
window.addEventListener('load', function () {
    let elements = document.querySelectorAll('.sn-sb-nav-item');
    elements.forEach((element) => {
        const language = element.getAttribute('lang') ? element.getAttribute('lang') : "en";
        let data_target = window.location.pathname;
        data_target = data_target.replace(`/${language}`, "");
        data_target = data_target.trim() != "" ? data_target : '/';

        if(element.getAttribute("data-target") == data_target){
            element.classList.add('active');
        }
    });
}, false);